import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useAuthStateValue } from '../auth/AuthContext';
import axios from 'axios';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import TabExistsDialog from './TabExistsDialogModal';
import constant from '../../utils/constants';

const TabManagement = () => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [user, setUser] = useAuthStateValue();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    try {
      const tab_id = sessionStorage.getItem(
        constant.LOCAL_STORAGE_UNIQUE_TAB_KEY
      );
      setDialogOpen(false);
      setUser(null);
      if (tab_id) {
        axios.post(`${base_url()}/api/remove-id-from-redis`, {
          tab_id,
        });
      }
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage(
        err.response?.data?.message || 'Could not handle tab close.'
      );
    }
  };

  const handleLoadHere = () => {
    try {
      setDialogOpen(false);

      const tab_id = sessionStorage.getItem(
        constant.LOCAL_STORAGE_UNIQUE_TAB_KEY
      );
      axios.post(`${base_url()}/api/logout-other-tabs`, {
        newSocketId: tab_id,
      });
    } catch (err) {
      console.error(err);
      setHasError(true);
      setErrorMessage(err.response?.data?.message || 'Could not handle tab.');
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Box>
        <TabExistsDialog
          open={dialogOpen}
          onClose={handleClose}
          onLoadHere={handleLoadHere}
        />
      </Box>
    </>
  );
};

export default TabManagement;
